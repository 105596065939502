<template>
<table class="table table-bordered">
  <thead>
  <tr>
    <td width="200px">Название</td>
    <td>{{subject.subjectName}}</td>
  </tr>
  <tr>
    <td>Класс</td>
    <td>{{subject.groupName}}</td>
  </tr>
  <tr>
    <td>Преподаватель</td>
    <td>{{subject.teacherName}}</td>
  </tr>
  </thead>

</table>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>

export default {
  name: "SubjectInfo",
  components: {
   
  },
  props:{
    subject: {
      type: Object,
      default: (()=>{})
    }
  },
  data() {
    return {
      
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      
    },
  }
};
</script>

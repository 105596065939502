<template>
  <div class="kt-container">
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="''">
          <template v-slot:title>
            <div v-if="quarter < 5">
              {{ $t("quarterMarks.title") }} - {{title}} // {{ $t("quarterMarks.quarter") }} - {{quarter}}
            </div>
            <div v-if="quarter === 5">
              {{ $t("quarterMarks.annualMark") }}  - {{title}}
            </div>
            <div v-if="quarter === 6">
              {{ $t("quarterMarks.examinationMarks") }}  - {{title}}
            </div>         
            <div v-if="quarter === 7">
              {{ $t("quarterMarks.finalMarks") }}  - {{title}}
            </div>
          </template>
          <template v-slot:toolbar>
            <button class="btn btn-success" @click="download">Скачать</button>
          </template>
          <template v-slot:body>
            <b-card no-body>
              <SubjectInfo :subject="subjectInfo"></SubjectInfo>
              
              <table class="table table-bordered">
                <thead class="thead-light">
                <tr>
                  <th width="5%">#</th>
                  <th width="20%">Ученики</th>
                  
                  <th width="10%" v-if="quarter !== 7">Среднее</th>
                  <th width="10%" v-if="quarter === 7">{{ $t("quarterMarks.annualMark") }}</th>
                  <th width="10%" v-if="quarter === 7">{{ $t("quarterMarks.examinationMarks") }}</th>
                  
                  <th width="10%">
                    <div v-if="quarter < 5">
                      {{ $t("quarterMarks.title") }}
                    </div>
                    <div v-if="quarter === 5">
                      {{ $t("quarterMarks.annualMark") }}
                    </div>
                    <div v-if="quarter === 6">
                      {{ $t("quarterMarks.examinationMarks") }}
                    </div>
                    <div v-if="quarter === 7">
                      {{ $t("quarterMarks.finalMarks") }}
                    </div>                  
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(student, k) in students" :key="k">
                  <td>{{k + 1}}</td>
                  <td>{{student.studentName}}</td>
                  <td v-if="quarter !== 7">{{averageMarks[student.studentId]}}</td>
                  <td v-if="quarter === 7">{{showMark(annualMarks[student.studentId])}}</td>
                  <td v-if="quarter === 7">{{showMark(examinationMarks[student.studentId])}}</td>
                  
                  <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                    <b-form-group>
                      <b-form-select
                          style="width: 100px"
                          id="input-x"
                          v-model="
                              marks[student.studentId][
                                subject.subjectId
                              ]
                            "
                          :options="marksList"
                          v-on:change="
                              changeMark(
                                student.studentId,
                                $event
                              )
                            "></b-form-select>
                    </b-form-group>
                    <b-form-group v-if="quarter === 6">
                      <b-form-select
                          style="width: 100px"
                          id="input-x"
                          v-model="
                              marks2[student.studentId][
                                subject.subjectId
                              ]
                            "
                          :options="marksList"
                          v-on:change="
                              changeMark(
                                student.studentId,
                                $event
                              )
                            "></b-form-select>
                    </b-form-group>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>5</td>
                  <td></td>
                  <td v-if="quarter === 7"></td>
                  <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                    {{mark5Count[subject.subjectId]}}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>4</td>
                  <td></td>
                  <td v-if="quarter === 7"></td>
                  <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                    {{mark4Count[subject.subjectId]}}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>3</td>
                  <td></td>
                  <td v-if="quarter === 7"></td>
                  <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                    {{mark3Count[subject.subjectId]}}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Качество знаний %</td>
                  <td></td>
                  <td v-if="quarter === 7"></td>
                  <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                    {{marksQuality[subject.subjectId].toFixed(2)}}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Успеваемость %</td>
                  <td></td>
                  <td v-if="quarter === 7"></td>
                  <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                    {{marksProgress[subject.subjectId].toFixed(2)}}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>СОУ %</td>
                  <td></td>
                  <td v-if="quarter === 7"></td>
                  <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                    {{marksSou[subject.subjectId].toFixed(2)}}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Средний балл</td>
                  <td></td>
                  <td v-if="quarter === 7"></td>
                  <td v-for="(subject, subjectIndex) in subjects" :key="subjectIndex">
                    {{marksAverageForSubject[subject.subjectId].toFixed(2)}}
                  </td>
                </tr>
                </tbody>
              </table>
            </b-card>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
.nav-tabs{
  display: none;
}

/*  table, th, td {
    border: 1px solid black;
  }*/
.hw-title{
  font-style: italic;
}

.mobile-day-select{
  display: none;
}

@media (max-width: 767px) {

  #marksTabs div.col-auto{
    display: none;
  }

  .mobile-day-select{
    display: block;
  }
}
th.rotate {
  writing-mode: vertical-rl;
}


</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import SubjectInfo from "../schools/Marks/partials/SubjectInfo.vue";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "quarterMarks",
  components: {
    SubjectInfo,
    KTPortlet
  },
  data() {
    return {
      title: '',
      quarter: this.$route.params.quarter,
      groupSubjectId: this.$route.params.groupSubjectId,
      students:[],
      subjects:{},
      subject:{},
      marksList: DictionariesService.getMarksForQuarter(),
      marks: {},
      marks2: {},
      marksAverageForStudent:{},
      mark5Count:{},
      mark4Count:{},
      mark3Count:{},
      marksQuality:{},
      marksProgress:{},
      marksSou:{},
      marksAverageForSubject:{},

      studentQuarterStartCount: 0,
      studentQuarterEndCount: 0,
      studentComeNames: {},
      withGoodMarks: 0,
      withMarks2: 0,
      withMarksNA: 0,
      withMarks2Names: '',
      withMarks2Subjects: '',
      withMarks5: 0,
      withMarks4: 0,
      withMarks3: 0,
      marksQualityAverage: 0,
      marksProgressAverage: 0,
      marksAverageForSubjectAverage: 0,
      
      //new 
      subjectInfo:{
        subjectName:'',
        groupName:'',
        teacherName:'',
      },
      averageMarks:{},
      annualMarks:{},
      examinationMarks:{},
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData(){
      let $this = this;
      let params = {
        quarter : this.$route.params.quarter,
        groupSubjectId: this.$route.params.groupSubjectId
      }
      ApiService.querySecured("marks/getQuarterMarksForSubject", {params:params})
          .then(({ data }) => {
            $this.title = data.groupName;
            $this.quarter = data.quarter;
            $this.students = data.students;
            $this.subjects = data.subjects;
            $this.subject = data.subjects[0];
            $this.subjectInfo = data.subjectInfo;
            $this.marks = {};

            let averageMarks = data.averageMarks;
            for (let i in averageMarks) {
              let item = averageMarks[i];

              $this.averageMarks[item.studentId] = item.markString;
            }

            for (let i in data.students) {
              let student = data.students[i];

              $this.marks[student.studentId] = {};
              $this.marks2[student.studentId] = {};
              $this.annualMarks[student.studentId] = 0;
              $this.examinationMarks[student.studentId] = 0;
            }

            for (let ii in data.marks){
              let mark  = data.marks[ii];

              if( typeof $this.marks[mark.studentId] != 'undefined'){
                $this.marks[mark.studentId][mark.subjectId] = mark.mark;
                $this.marks2[mark.studentId][mark.subjectId] = mark.mark2;
              }
            }

            
            for (let omi in data.otherQuarterMarks) {
              let omiData = data.otherQuarterMarks[omi];

              if(omiData.quarter === 5){
                if( typeof $this.annualMarks[omiData.studentId] != 'undefined'){
                  $this.annualMarks[omiData.studentId] = omiData.mark
                }
              }else if(omiData.quarter === 6){
                if( typeof $this.examinationMarks[omiData.studentId] != 'undefined'){
                  $this.examinationMarks[omiData.studentId] = omiData.mark
                }
              }
            }

            this.countStat();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    countStat(){
      let marksAverageForStudent = {};
      let marks5 = {};
      let marks4 = {};
      let marks3 = {};
      let marks2 = [];
      let marks2Subject = {};
      let marksNA = {};

      let marks5Students = [];
      let marks4Students = [];
      let marks3Students = [];
      let marks2Students = [];
      let marksNAStudents = [];

      let marksQuality = {};
      let marksProgress = {};
      let marksSou = {};      
      let marksAverageForSubject = {};
      for (let subjectIndex in this.subjects){
        let subjectId = this.subjects[subjectIndex].subjectId;
        marks5[subjectId] = 0;
        marks4[subjectId] = 0;
        marks3[subjectId] = 0;
        marks2Subject[subjectId] = 0;
        marksNA[subjectId] = 0;        
        marksQuality[subjectId] = 0;
        marksProgress[subjectId] = 0;
        marksAverageForSubject[subjectId] = 0;
      }

      for (let stIndex in this.students){
        let student = this.students[stIndex];
        let cc = 0;
        let marksSum = 0;
        for (let subjectId  in this.marks[student.studentId]){
          let mark = parseInt(this.marks[student.studentId][subjectId]);
          if ( mark > 1) {
            marksSum += mark;
            cc++;
            switch (mark){
              case 5:
                marks5[subjectId] += 1;
                if(!marks5Students.includes(student.studentId)){
                  marks5Students.push(student.studentId);
                }
                break;
              case 4:
                marks4[subjectId] += 1;
                if(!marks4Students.includes(student.studentId)){
                  marks4Students.push(student.studentId);
                }
                break;
              case 3:
                marks3[subjectId] += 1;
                if(!marks3Students.includes(student.studentId)){
                  marks3Students.push(student.studentId);
                }
                break;
              case 2:
                marks2Subject[subjectId] += 1;
                if(!marks2.includes(subjectId)){
                  marks2.push(parseInt(subjectId));
                }
                if(!marks2Students.includes(student.studentId)){
                  marks2Students.push(student.studentId);
                }
                break;
              case 8:
                marksNA[subjectId] += 1;
                if(!marksNAStudents.includes(student.studentId)){
                  marksNAStudents.push(student.studentId);
                }
                break;
              default:
                break;
            }
          }
        }

        if(cc > 0){
          marksAverageForStudent[student.studentId] = (marksSum/cc).toPrecision(2);
        }
      }

      this.withMarks5 = 0;
      this.withMarks4 = 0;
      this.withMarks3 = 0;
      this.withMarks2 = 0;
      this.withMarksNA = 0;

      for (let index5 in marks5Students){
        let item = marks5Students[index5];
        if(
            !marks2Students.includes(item)
            && !marks3Students.includes(item)
            && !marks4Students.includes(item)
        ){
          this.withMarks5 = this.withMarks5 +1;
        }
      }

      for (let index5 in marks4Students){
        let item = marks5Students[index5];
        if(
            !marks2Students.includes(item)
            && !marks3Students.includes(item)
        ){
          this.withMarks4 = this.withMarks4 +1;
        }
      }

      for (let index5 in marks3Students){
        let item = marks5Students[index5];
        if(
            !marks2Students.includes(item)
        ){
          this.withMarks3 = this.withMarks3 +1;
        }
      }

      let studentWith2List = [];
      for (let item in this.students){
        let student = this.students[item];
        if(marks2Students.includes(student.studentId)){
          studentWith2List.push(student.studentName);
        }
      }
      this.withMarks2Names = studentWith2List.toString();

      this.withMarks2 = marks2Students.length;
      this.withGoodMarks = this.withMarks5 + this.withMarks4 + this.withMarks3;

      let subjectsWith2List = [];
      let marksQualityAverage = 0;
      let marksProgressAverage = 0;
      let marksAverageForSubjectAverage = 0;
      let studentsCount = this.students.length;
      if(studentsCount > 0){
        for (let subjectIndex in this.subjects){
          let subject = this.subjects[subjectIndex];
          let subjectId = subject.subjectId;
          if(marks2.includes(subjectId)){
            subjectsWith2List.push(subject.subjectName);
          }

          marksQuality[subjectId] = (100 * (marks5[subjectId] + marks4[subjectId]) / studentsCount);
          marksProgress[subjectId] = (100 * (marks5[subjectId] + marks4[subjectId] + marks3[subjectId]) / studentsCount);
          marksSou[subjectId] = (100 * marks5[subjectId] + 64 * marks4[subjectId] + 36 * marks3[subjectId] + 16 * marks2Subject[subjectId] + 7 * marksNA[subjectId]) / studentsCount;
          marksAverageForSubject[subjectId] = ((marks5[subjectId] * 5 + marks4[subjectId] * 4 + marks3[subjectId] * 3) / studentsCount);

          marksQualityAverage += marksQuality[subjectId];
          marksProgressAverage += marksProgress[subjectId];
          marksAverageForSubjectAverage += marksAverageForSubject[subjectId];

          //marksQuality[subjectId] = marksQuality[subjectId];
          //marksProgress[subjectId] =  marksProgress[subjectId];
          //marksAverageForSubject[subjectId] = marksAverageForSubject[subjectId];
        }
      }
      let subjectsCount = this.subjects.length;
      if(subjectsCount > 0){
        this.marksQualityAverage = (marksQualityAverage/subjectsCount).toPrecision(2);
        this.marksProgressAverage = (marksProgressAverage/subjectsCount).toPrecision(2);
        this.marksAverageForSubjectAverage = (marksAverageForSubjectAverage/subjectsCount).toPrecision(2);
      }

      this.withMarks2Subjects = subjectsWith2List.toString();
      this.studentQuarterStartCount = this.students.length;
      this.studentQuarterEndCount = this.students.length;
      this.studentComeNames = '';

      this.marksAverageForStudent = marksAverageForStudent;
      this.mark5Count = marks5;
      this.mark4Count = marks4;
      this.mark3Count = marks3;
      this.marksQuality = marksQuality;
      this.marksProgress = marksProgress;
      this.marksSou = marksSou;
      this.marksAverageForSubject = marksAverageForSubject;
    },
    download(){
      let params = {
        quarter : this.$route.params.quarter,
        subjectId: this.$route.params.groupSubjectId
      }
      let $this = this;
      ApiService.downloadSecured("marks/downloadQuarterMarkForSubject", params)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Ведомость ' + $this.title + '.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    changeMark(studentId/*, event*/) {
      //let markVal = event;
      let markVal = this.marks[studentId][this.subject.subjectId];
      let markVal2 = this.marks2[studentId][this.subject.subjectId];

      let mark = {
        groupSubjectId: this.subject.subjectId,
        subjectSubgroupId: this.subject.subjectSubgroupId,
        studentId: studentId,
        quarter: this.quarter,
        mark: markVal,
        mark2: markVal2,
      };
      let $this = this;
      ApiService.postSecured("marks/saveQuarterMark", mark)
          .then(function() {
            $this.countStat();
          })
          .catch(() => {
            alert("Ошибка, перезайдите или обратитесь к администратору");
          });
    },
    showMark(mark){
      return DictionariesService.displayQuarterMark(mark);
    }
  }
};
</script>
